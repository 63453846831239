export default async function checkConnection() {
  let promise = new Promise((resolve, reject) => {
    var condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      // check for internet connectivity by grabbing google.com
      // consider that if this fails we may want to try a seoncd site
      // in case google does happen to be down?
      fetch('https://www.google.com/', {
        mode: 'no-cors',
        })
      .then(() => {
        console.log('internet connectivity: true');
        resolve(true)
      }).catch(() => {
       console.log('internet connectivity: false');
       resolve(false)
      })

    } else{
      console.log('internet connectivity: false')
      resolve(false)
    }
  });

  let result = await promise;
  return result;
};

// source:
// https://stackoverflow.com/questions/40248639/detect-network-connection-in-react-redux-app-if-offline-hide-component-from-u
