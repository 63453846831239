import axios from 'axios'
import db from '../../utils/dexie-provider.js'

import store from '../store'
import { increment, decrement } from '../slices/syncSlice'

// import { nanoid } from 'nanoid'
// import dayjs from 'dayjs'

// getPlans

export function getPlots() {
  console.log('getting all plots')

  axios.get('/api/v1/plots')
  .then(res => {
    if(res.status === 200) {
      res.data.forEach(function (obj, index) {
        store.dispatch(increment())

        db.plots.put(obj, obj.appId).then(function(){
          // console.log('plot added')
          store.dispatch(decrement())

        }).catch(function(error) {
           alert ("Unable to save plot locally. Contact support.\nError: " + error);
        });
      });

    } else {
      console.log('error')
    }
  }).catch(error => {
    console.log(error)
    alert('Error getting data. Contact support.')
  })
}
