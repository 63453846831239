import React from 'react'

const Component = (props) => {
  const plotTypes = props.plotTypes;

  return (
    <div className="modal fade" id="keyModal" tabIndex="-1" aria-labelledby="keyModal" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="keyModal">Map Key</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {plotTypes && plotTypes.sort((a, b) => (a.name > b.name) ? 1 : -1).map((obj, index) => (
              <div key={'obj-'+index} className="d-flex align-content-center map-key">
                {obj.display && obj.display.fill && (
                  <div style={{backgroundColor:obj.display.fill}}></div>
                )}
                { (!obj.display || !obj.display.fill) && (
                  <div></div>
                )}
                <p className="mb-0">{obj.name}</p>
              </div>
            ))}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Component;
