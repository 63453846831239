// think about sending props down to show back button or not
// and maybe the help content could show up in a modal here or something?
// add a title, so default is ICP unless a prop is passed

// also should put the div here for the help stuff
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom'
import { ArrowLeftShort, JournalText } from 'react-bootstrap-icons' // QuestionCircle

const Component = (props) => {
  let history = useHistory();
  let siteObj = props.siteObj;
  let title = props.title ? props.title : 'ICP'
  let backBtn = props.backBtn;
  let showSwppp = props.showSwppp;

  const handleOnClick = useCallback(() => {
    if(backBtn)
      history.push(backBtn)
    else
      history.goBack()
  }, [backBtn, history]); // this does work


  // passing site code in case we want to display it here
  // console.log(props.siteCode)

  return (
    <nav className="navbar navbar-expand navbar-dark text-white fixed-top bg-dark shadow pt-2" aria-label="Second navbar example">
      <div className="container-fluid">
        <ArrowLeftShort className="mr-auto pointer" size={35} onClick={handleOnClick} />
        <ul className="navbar-nav mx-auto">
          <li className="nav-item pointer" onClick={() => history.push('/')}>
            {title}
          </li>
        </ul>
        <div></div>
        { showSwppp && (
          <JournalText className="pointer" size={25} onClick={() => history.push('/'+siteObj.appId+'/swppp')} />
        )}
      </div>
    </nav>
  )
}

export default Component;
