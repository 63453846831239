import axios from 'axios'
import db from '../../utils/dexie-provider.js'

import store from '../store'
import { increment, decrement } from '../slices/syncSlice'

export function getPlotTypes() {
  console.log('getting all plot types')
  axios.get('/api/v1/plot-types')
  .then(res => {
    if(res.status === 200) {
      res.data.forEach(function (obj, index) {
        store.dispatch(increment())

        db.plotTypes.put(obj, obj.appId).then(function(){
          // console.log('plot type added')
          store.dispatch(decrement())

        }).catch(function(error) {
           alert ("Unable to save plot type locally. Contact support.\nError: " + error);
        });
      });

    } else {
      console.log('error')
    }
  }).catch(error => {
    console.log(error)
    alert('Error getting data. Contact support.')
  })
}
