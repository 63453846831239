import { saveData }  from '../data/sync.js'

export default function handlePhoto(file, prefix, siteId, planId, plotId, inspectionId, callback) {
  // this script:
  // opens a photo file
  // reads the image information
  // resizes it as needed
  // saves it locally as base64 with appropriate info
  // then tries to sync it...?

  // Create an image and new file reader
  var img = document.createElement('img');
  var reader = new FileReader();

  // Set the image once loaded into file reader
  reader.onload = function(e) {
    img.src = e.target.result;

    img.onload = function () {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      var MAX_WIDTH = 2000;
      var MAX_HEIGHT = 2000;
      var width = img.width;
      var height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      canvas.width = width;
      canvas.height = height;
      var ctx2 = canvas.getContext('2d');
      ctx2.drawImage(img, 0, 0, width, height);
      let base64 = canvas.toDataURL('image/jpeg');

      var currentTime = new Date().getTime();
      let photoData = {
        siteId,
        planId,
        plotId,
        inspectionId,
        base64,
        fileName: prefix+'-'+currentTime+'.jpeg',
        fileType: 'image/jpeg'
      }

      // console.log('photoData: ', photoData)
      saveData('photos',photoData);
      callback();
    } // end img onload
  } // end reader onload

  // Load files into file reader
  reader.readAsDataURL(file);
};

// async await if we need it.
// https://stackoverflow.com/a/53269990/983017
