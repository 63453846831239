import axios from 'axios'
import db from '../../utils/dexie-provider.js'

import store from '../store'
import { increment, decrement } from '../slices/syncSlice'

export function getChecklists(dispatch) {
  console.log('getting all checklists')
  axios.get('/api/v1/checklists')
  .then(res => {
    if(res.status === 200) {
      res.data.forEach(function (obj, index) {
          store.dispatch(increment())

        db.checklists.put(obj, obj.appId).then(function(){
          // console.log('checklist added')
          store.dispatch(decrement())

        }).catch(function(error) {
           alert ("Unable to save checklist locally. Contact support.\nError: " + error);
        });
      });

    } else {
      console.log('error')
    }
  }).catch(error => {
    console.log(error)
    alert('Error getting data. Contact support.')
  })
}
