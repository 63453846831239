// import dayjs from 'dayjs'
import db from '../../utils/dexie-provider.js'

export function deleteDb() {
  console.log('request to delete the db');

  let confirmDel = window.confirm("Are you sure you want to delete your local data?\n\nThis action cannot be undone!");
  if(confirmDel) {
    db.delete().then(() => {
      window.location.reload();

    }).catch((err) => {
      alert('Error deleting database. Contact support.');
      console.error("Could not delete database");

    });
  }
}
