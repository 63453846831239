import Dexie from 'dexie';
const db = new Dexie('ICP');

db.version(1).stores({
  system: "name",
  sites: "appId,siteCode,createdAt,updatedAt,dirty",
  plans: "appId,siteId,name,createdAt,updatedAt,dirty",
  checklists: "appId,name",
  inspections: "appId,siteId,name,status,createdAt,updatedAt,dirty",
  plotTypes: "appId,name,dirty",
  plots: "appId,siteId,planId,plotId,inspectionId,dirty",
  photos: "appId,siteId,planId,plotId,inspectionId,createdAt,dirty"
});

export default db;
