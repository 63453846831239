import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'

import db from '../utils/dexie-provider.js'
import { useLiveQuery } from 'dexie-react-hooks'

import { deleteDb } from '../data/tables/system.js'
import { getData }  from '../data/sync.js'

import Loading from './components/Loading'
import NewInspection from './components/NewInspection'

import { Collapse, Modal } from 'bootstrap'
import { ChevronCompactRight } from 'react-bootstrap-icons'

const View = (props) => {
  const versionNo = '6.72';
  const { logout } = useAuth0();
  const history  = useHistory();

  // const systemInfo = useLiveQuery(() => db.system.toArray(), []);
  const allSites = useLiveQuery(() => db.sites.orderBy('siteCode').toArray(), []);
  const allChecklists  = useLiveQuery(() => db.checklists.toArray(), []);
  const allInspections = useLiveQuery(() => db.inspections.orderBy('name').toArray(), []);

  const toggleNav = (navId) => {
    var bsCollapse = Collapse.getOrCreateInstance(document.getElementById(navId))
    bsCollapse.toggle();
  }

  const showModal = (modalId) => {
    var bsModal = Modal.getOrCreateInstance(document.getElementById(modalId))
    bsModal.show();
  }

  const hideModal = (modalId) => {
    var bsModal = Modal.getOrCreateInstance(document.getElementById(modalId))
    bsModal.hide();
  }

  // lklklk - do a filter to get the 'last pull' value if it exists
  // then update the view below -- right now it's not really working how you want

  return (
    <>
      <nav className="navbar navbar-dark text-white fixed-top bg-dark shadow pt-2">
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" onClick={() => toggleNav('navbarNav')}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              ICP
            </li>
          </ul>
          <button type="button" className="btn btn-outline-light rounded-pill" onClick={() => showModal('addNewModal')}>Add New</button>
          <button type="button" className="btn btn-outline-light rounded-pill ms-2" onClick={() => showModal('startModal')}>Help</button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav pt-3">
              <li className="nav-item">
                <button type="button" className="btn nav-link" onClick={() => showModal('settingsModal')}>Settings</button>
              </li>
              <li className="nav-item">
                <button type="button" className="btn nav-link" onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="col">

            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="inspections-tab" data-bs-toggle="pill" data-bs-target="#inspections" type="button" role="tab" aria-controls="inspections" aria-selected="true">Inspections</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="setup-tab" data-bs-toggle="pill" data-bs-target="#setup" type="button" role="tab" aria-controls="setup" aria-selected="false">Site Setup</button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="inspections" role="tabpanel" aria-labelledby="inspections-tab">
                { allInspections && allInspections.length === 0 && (
                  <p className="mt-4">No inspections yet. <span className="pointer" onClick={() => showModal('addNewModal')}><u>Tap here</u></span> to create a new inspection.</p>
                )}
                { allInspections && allInspections.length>0 && allInspections.filter(obj => obj.status === 'A').sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt))).map((obj, index) => {
                  let found = allChecklists.find(checklist => checklist.appId === obj.checklistId);
                  if(found === undefined) {
                    found = { name: 'Recurring' }
                  }

                  return (
                    <div key={'setup-'+index} className="position-relative bg-white rounded shadow-sm py-3 ps-3 mb-3 pointer"
                      onClick={(e) => {
                        history.push('/inspection/'+obj.siteId+'/'+obj.appId)
                      }}
                    >
                      <h6>{found.name.toUpperCase()}</h6>
                      {obj.name}
                      <div className="position-absolute top-50 end-0 translate-middle"><ChevronCompactRight size={36} className="d-block" /></div>
                      { obj.inspectionStatus === 'Pending' && (
                        <div className="position-absolute bottom-0 end-0 me-4 px-5 p-3 small text-muted">Pending</div>
                      )}

                      { obj.inspectionStatus === 'In Progress' && (
                        <div className="position-absolute bottom-0 end-0 me-4 px-5 p-3 small text-warning">In Progress</div>
                      )}

                      { obj.inspectionStatus === 'Complete' && (
                        <div className="position-absolute bottom-0 end-0 me-4 px-5 p-3 small text-success">Complete</div>
                      )}
                    </div>
                  )}
                )}
              </div>
              <div className="tab-pane fade" id="setup" role="tabpanel" aria-labelledby="setup-tab">
                { allSites && allSites.length>0 && allSites.filter(obj => obj.status === 'A').map((obj, index) =>
                  <div key={'setup-'+index} className="position-relative bg-white rounded shadow-sm py-3 ps-3 mb-3 pointer"
                    onClick={(e) => {
                      history.push('/site/'+obj.appId)
                    }}
                  >
                    <h6>Setup SWPPP</h6>
                    {obj.siteCode}
                    <div className="position-absolute top-50 end-0 translate-middle"><ChevronCompactRight size={36} className="d-block" /></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="settingsModal" tabIndex="-1" aria-labelledby="settingsModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="settingsModalLabel">Settings</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <button type="button" className="btn btn-primary"
                onClick={e => {
                  getData();
                  toggleNav('navbarNav');
                  hideModal('settingsModal');
                }}>Refresh Maps/Data</button>
              <br /><br />
              <button type="button" className="btn btn-danger" onClick={e => deleteDb()}>Clear Your Tablet Data</button>
            </div>
            <div className="modal-footer">
              {/* here is where lastPull date would go */}
              <p className="me-auto text-muted">{versionNo}</p>
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="startModal" tabIndex="-1" aria-labelledby="startModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="startModalLabel">Overview</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Welcome to Amazon's Interactive Compliance Plan! ICP is where you can quickly see a map of your facility, where assets are located and what condition each asset is in.</p>
              <p>This system is currently in place for Amazon's Weekly and the Quarterly site inspections.</p>
              <p>This is the <b>"Inspections"</b> page. Here you will see each site's scheduled inspection and the type of inspection taking place.</p>
              <img src="/screen-1.png" className="img-fluid border d-block mb-3" alt="Instruction Screenshot" />
              <p>Menu - The very first time you open your app "clean your data" in the settings tab.</p>
              <img src="/screen-2.png" className="img-fluid border d-block mb-3" alt="Instruction Screenshot" />
              <p>Add New - When you're ready to create a new inspection, choose your site and inspection type.</p>
              <img src="/screen-3.png" className="img-fluid border d-block mb-3" alt="Instruction Screenshot" />
              <p>Selecting an Inspection - Select which inspection you will be conducting.</p>
              <img src="/screen-4.png" className="img-fluid border d-block mb-3" alt="Instruction Screenshot" />
              <p>Weekly Inspection - In the center of the page is the inspection you are completing.</p>
              <img src="/screen-4-1.png" className="img-fluid border d-block mb-3" alt="Instruction Screenshot" />
              <p>Off-line Mode - If at any point you disconnect from the Internet during your inspection, don't worry! Continue to work off-line and when you reconnect to wifi all of your data will be uploaded.</p>
              <img src="/screen-4-1-1.png" className="img-fluid border d-block mb-3" alt="Instruction Screenshot" />
              <p>Weekly Inspection - On the left hand side select the status of the inspection and any notes you would like to add.</p>
              <img src="/screen-4-2.png" className="img-fluid border d-block mb-3" alt="Instruction Screenshot" />
              <p>Weekly Inspection- On the top of the page is training information and a quick video in case you need a refresher on the weekly inspection.</p>
              <img src="/screen-4-3.png" className="img-fluid border d-block mb-3" alt="Instruction Screenshot" />
              <p>Weekly Inspection - Select view map to help find assets and take photos of assets to track their history.</p>
              <img src="/screen-4-4.png" className="img-fluid border d-block mb-3" alt="Instruction Screenshot" />
              <p>Weekly Inspection - For now you manually have to put this information into Austin. In the near future you will be able to save in your kindle and push your inspection directly to Austin.</p>
              <img src="/screen-4-5.png" className="img-fluid border d-block mb-3" alt="Instruction Screenshot" />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <NewInspection checklists={allChecklists} sites={allSites} />
    </>
  )
}

export default withAuthenticationRequired(View, {
  onRedirecting: () => (<Loading />)
});
