import React, { useEffect, useState, useRef } from 'react'
import { fabric } from 'fabric'
import { drawPlot } from '../../utils/map-plot'

const View = (props) => {
  const debugging = false;
  const [canvas, setCanvas] = useState(null)
  var isTouching = false;
  var isPinching = false;
  const panning = useRef(false);
  const objSelected = useRef(false);
  const hasDragged = useRef(false);
  const mapRef = useRef(null);
  const panX = useRef(0);
  const panY = useRef(0);

  const plots = props.plots;
  const plotTypes = props.plotTypes;
  const planObj = props.planObj;
  const mapEl = props.mapEl;

  useEffect(() => {
    if(!planObj || !plotTypes || !plots) {
      console.log('waiting to initialize fabric for map data')
      return;
    }

    // console.clear();
    console.log('initializing fabric')
    let canvas = new fabric.Canvas(mapEl);
    document.getElementById(mapEl).fabric = canvas;
    fabric.Object.prototype.originX = fabric.Object.prototype.originY = 'center';
    fabric.Object.prototype.borderColor = 'red';
    fabric.Object.prototype.objectCaching = false;
    fabric.Object.prototype.lockScalingX = true;
    fabric.Object.prototype.lockScalingY = true;

    fabric.Object.prototype.lockMovementX = false;
    fabric.Object.prototype.lockMovementY = false;
    canvas.hoverCursor = 'move';

    // add padding to text pieces
    fabric.Text.prototype.set({
      _getNonTransformedDimensions() { // Object dimensions
        return new fabric.Point((this.width*1.1), (this.height*1)).scalarAdd(this.padding);
      },
      _calculateCurrentDimensions() { // Controls dimensions
        return fabric.util.transformPoint(this._getTransformedDimensions(), this.getViewportTransform(), true);
      }
    });

    let bkgd = new Image();
    bkgd.crossOrigin = "anonymous";
    bkgd.src = planObj.url;
    bkgd.onload = function() {
      canvas.selection = false;
      let width = mapRef.current ? mapRef.current.offsetWidth : 100;
      let height = mapRef.current ? mapRef.current.offsetHeight : 100;
      let initialScale = width/bkgd.width;
      canvas.setWidth(width);
      canvas.setHeight(height);
      canvas.setZoom(initialScale);
      canvas.setBackgroundImage(planObj.url, canvas.renderAll.bind(canvas), {
        backgroundImageOpacity: 0.5,
        backgroundImageStretch: false,
        originX: 'left',
        originY: 'top',
        stroke: '#666',
        strokeWidth: 1,
      });

      if(canvas) {
        // enableInteractions(canvas);
        loadPlots(canvas);
      }
    }

    // need to figure out a way to reset this once it's unmounted...
    // https://medium.com/@albertogasparin/forcing-state-reset-on-a-react-component-by-using-the-key-prop-14b36cd7448e
    return () => {
      console.log('unmounting, clear out canvas')
      canvas.wrapperEl.parentElement.innerHTML = '<canvas id="'+mapEl+'"></canvas>';
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keydown', downHandler);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapEl, planObj, plotTypes, plots]);

  // display map data
  const loadPlots = (canvas) => {
    if(plots.length > 0) {
      let filteredPlots = plots.filter(plot => !plot.inspectionId);
      for (const plot of filteredPlots){
        if(plot.status === 'A') {
          let display = drawPlot(plotTypes, null, planObj, plot, null);
          canvas.add(display)
        }
      }
      canvas.renderAll();
    }
  }

  const enableInteractions = (canvas) => {
    if(canvas) {
      window.addEventListener('resize', (e) => { handleResize(e, canvas) });
      window.addEventListener('keydown', downHandler);
      canvas.wrapperEl.parentElement.addEventListener('wheel', (e) => { wheelHandler(e, canvas) });

      canvas.on('selection:created', (e) => { handleSelectionCreated(e) })
      canvas.on('selection:cleared', (e) => { handleSelectionCleared(e) })
      canvas.on('touch:drag', (e) => { handleTouchDrag(e, canvas) })
      canvas.on('touch:gesture', (e) => { handleTouchGesture(e, canvas) })

      canvas.on('mouse:down', (e) => {
        if(debugging) console.log('mouse down')
        isTouching = true;
        if(!objSelected.current) panning.current = true;

        // restart the pan so we don't jump
        // but only if we aren't gesturing?
        if(!isPinching) {
          panX.current = e.absolutePointer.x;
          panY.current = e.absolutePointer.y;
        }
      });

      canvas.on('mouse:up', (e) => {
        isTouching = false;
        isPinching = false;
        if(debugging) console.log('mouse up')
        if(!objSelected.current) panning.current = false;

        if(objSelected.current && e.target) {
          let plot = e.target;
          canvas.discardActiveObject().renderAll();
        }

        // reset has dragged
        hasDragged.current = false;
      })

      setCanvas(canvas);
    }
  }

  const downHandler = (e) => {
    if(debugging) console.log('key down')
  }

  const handleSelectionCreated = (e) => {
    if(debugging) console.log('selection created')
    objSelected.current = true;
  }

  const handleSelectionCleared = (e) => {
    if(debugging) console.log('selection cleared')
    objSelected.current = false;
  }

  const handleTouchDrag = (e, canvas) => {
    if (panning.current && !objSelected.current && e.self.x && e.self.y && e.e.type) {
      if(debugging) console.log('touch drag')
      hasDragged.current = true;

      // if (e.e.touches && e.e.touches.length === 1) {
        let xChange = e.self.x - panX.current;
        let yChange = e.self.y - panY.current;

        if( (Math.abs(xChange) <= 50) &&
            (Math.abs(yChange) <= 50) ) {
          var delta = new fabric.Point(xChange, yChange);
          canvas.relativePan(delta);
        }

        panX.current = e.self.x;
        panY.current = e.self.y;
      // }
    }
  }

  const handleTouchGesture = (e, canvas) => {
    if(debugging) console.log('touch:gesture');
    isPinching = true;

    // lklklk probably need to lock all objects to avoid moving

    if (e.e.touches && e.e.touches.length === 2) {
      var point = new fabric.Point(e.self.x, e.self.y);
      let zoomStartScale = 1;
      if (e.self.state === 'start') {
        zoomStartScale = canvas.getZoom();
      }
      var delta = zoomStartScale * e.self.scale;
      canvas.zoomToPoint(point, delta);
    }
  }

  const wheelHandler = (e, canvas) => {
    if(e.target.tagName === 'CANVAS') {
      e.preventDefault();
      var delta = e.detail ? e.detail * (-120) : e.wheelDelta ? e.deltaY : e.deltaX ? e.deltaX : e.deltaY;
      var curZoom = canvas.getZoom();
      var newZoom = curZoom + delta / 1000;
      var x = e.offsetX;
      var y = e.offsetY;

      if (newZoom > 0.2 && newZoom < 4) {
        canvas.zoomToPoint({
          x: x,
          y: y
        }, newZoom);
      }
      // if (event != null) event.preventDefault();
      canvas.requestRenderAll();
      canvas.calcOffset();
      return false;
    }
  }

  const handleResize = (e, canvas) => {
    let width = mapRef.current ? mapRef.current.offsetWidth : 100;
    let height = mapRef.current ? mapRef.current.offsetHeight : 100;
    // console.log('handling resize', width, height)
    canvas.setWidth(width);
    canvas.setHeight(height);
  }

  return (
    <div style={{height: '100%'}} ref={mapRef}>
      {!canvas && (
        <p>loading...</p>
      )}

      <canvas id={mapEl}></canvas>
    </div>
  );
}

export default View;

// bring to front:
// https://codesandbox.io/s/old-wood-k0wo2
