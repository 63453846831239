import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'

import { useForm } from 'react-hook-form'
import { updateData }  from '../data/sync.js'

import Dexie from 'dexie'
import db from '../utils/dexie-provider.js'

import { Modal } from 'bootstrap'
import FormBlock from './components/FormBlock'
import Loading from './components/Loading'
import Header   from './components/Header'

const View = (props) => {
  const history  = useHistory();
  const { user } = useAuth0();
  const { email } = user;
  const spawn = Dexie.spawn;
  const siteId = props.match.params.siteId;
  const inspectionId = props.match.params.inspectionId;
  const [siteObj, setSiteObj] = useState({})
  const [inspectionObj, setInspectionObj] = useState({})
  const [checklistObj, setChecklistObj] = useState({})
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(null);
  const { register, handleSubmit, formState: { errors }, clearErrors, setValue, reset } = useForm();

  let title = siteObj.siteCode ? siteObj.siteCode : 'TBD';
  let checklistTitle = checklistObj.name ? checklistObj.name : 'Routine';

  useEffect(() => {
    if(siteId) {
      spawn(function*() {
        let siteData   = yield db.sites.get(siteId);
        let checklists = yield db.checklists.toArray();
        if(siteData !== undefined) setSiteObj(siteData)
        console.log('--------')
        console.log(siteData)

        if(inspectionId) {
          let inspectionData  = yield db.inspections.get(inspectionId);
          setInspectionObj(inspectionData)
          setValue('inspectionStatus',inspectionData.inspectionStatus)
          setValue('inspectionNotes', inspectionData.inspectionNotes)
          setValue('udf1', inspectionData.udf1)
          setValue('udf2', inspectionData.udf2)
          setValue('udf3', inspectionData.udf3)
          setValue('udf4', inspectionData.udf4)
          setValue('udf5', inspectionData.udf5)
          setValue('udf6', inspectionData.udf6)
          setValue('udf7', inspectionData.udf7)
          setValue('udf8', inspectionData.udf8)
          setValue('udf9', inspectionData.udf9)
          setValue('udf10', inspectionData.udf10)
          console.log(inspectionData)

          if(checklists !== undefined) {
            let found = checklists.find(checklist => checklist.appId === inspectionData.checklistId);
            if(found !== undefined) {
              console.log(found)
              setChecklistObj(found)
            }
          }
        }

      }).then(function() {
         // finished loading plan information

      }).catch(function(e) {
        console.log('Error loading site ID')
        setError('Missing site');
      });
    }

    return () => {
      console.log('unmounting, clear out form')
      reset();
      clearErrors();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, inspectionId])

  const onSubmit = data => {
    setIsSaving('Saving...');
    setTimeout(function() {
      setIsSaving('Saved!');

      setTimeout(function() {
        setIsSaving(null);
      }, 750);
    }, 750);
    const formData = data;
    formData.appId = inspectionObj.appId;
    formData.updatedBy = email;
    console.log(formData)
    updateData('inspections',formData);
    // history.goBack();
  }

  const showModal = (modalId) => {
    var bsModal = Modal.getOrCreateInstance(document.getElementById(modalId))
    bsModal.show();
  }

  return (
    <>
      <Header siteObj={siteObj} title={siteObj.siteCode + ' Inspection'} backBtn="/" showSwppp={true} />
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="col">

            { error==='Missing site' && (
              <p>Site not found.</p>
            )}

            { !error && (
              <>
                <button type="button" className="float-end btn btn-light shadow-sm rounded-pill px-3 me-3"
                  onClick={(e) => { history.push('/inspection/site/'+siteId+'/'+inspectionId) }}>
                  View Map
                </button>

                { checklistObj && checklistObj.instructions && (
                  <button type="button" className="float-end btn btn-light shadow-sm rounded-pill px-3 me-3"
                    onClick={(e) => { showModal('instructionsModal') }}>
                    View Instructions
                  </button>
                )}

                <h6>{checklistTitle.toUpperCase()}</h6>
                <h1>{title}</h1>

                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="mt-3">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="mt-3">
                        <label>Status</label><br />
                          <div className="btn-group-vertical" role="group" aria-label="Basic checkbox toggle button group">
                            <input type="radio" className="btn-check bg-white" autoComplete="off" {...register("inspectionStatus", {required: true})} value="Pending" />
                            <label style={{width: 150}} className="btn btn-outline-primary btn-lg shadow-sm"
                              onClick={() => {
                                setValue('inspectionStatus', 'Pending')
                                clearErrors('inspectionStatus')
                                handleSubmit(onSubmit)();
                              }}>Pending</label>

                            <input type="radio" className="btn-check bg-white" autoComplete="off" {...register("inspectionStatus", {required: true})} value="In Progress" />
                            <label style={{width: 150}} className="btn btn-outline-primary btn-lg shadow-sm"
                              onClick={() => {
                                setValue('inspectionStatus', 'In Progress')
                                clearErrors('inspectionStatus')
                                handleSubmit(onSubmit)();
                              }}>In Progress</label>

                            <input type="radio" className="btn-check bg-white" autoComplete="off" {...register("inspectionStatus", {required: true})} value="Complete" />
                            <label style={{width: 150}} className="btn btn-outline-primary btn-lg shadow-sm"
                              onClick={() => {
                                setValue('inspectionStatus', 'Complete')
                                clearErrors('inspectionStatus')
                                handleSubmit(onSubmit)();
                              }}>Complete</label>
                          </div>
                        {errors.inspectionStatus && <div className="error">Please select one</div>}
                      </div>

                      <div className="mt-3">
                        <label>Notes/Comments</label><br />
                        <textarea className="form-control shadow-sm" {...register("inspectionNotes")} rows="3"></textarea>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      { checklistObj && checklistObj.schema && checklistObj.schema.map((obj, index) => (
                        <FormBlock key={"form-"+index} obj={obj} register={register} errors={errors} clearErrors={clearErrors} setValue={setValue} />
                      ))}
                    </div>
                  </div>
                  { !isSaving && (
                    <button id="save-btn" type="submit" className="btn btn-success rounded-pill shadow-sm" onClick={e => e.target.blur()}>Save</button>
                  )}

                  { isSaving && (
                    <button id="save-btn" type="button" className="btn btn-success rounded-pill shadow-sm">{isSaving}</button>
                  )}
                </form>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="modal fade" id="instructionsModal" tabIndex="-1" aria-labelledby="instructionsModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content bg-tan">
            <div className="modal-body">
              <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
              <h5 className="modal-title mb-3" id="instructionsModalLabel">Instructions</h5>
              { checklistObj && checklistObj.instructions && checklistObj.instructions.map((obj, index) => (
                <FormBlock key={"form-"+index} obj={obj} register={register} errors={errors} clearErrors={clearErrors} setValue={setValue} />
              ))}
              <div className="clearfix mt-4 mb-1">
                <button type="button" className="float-end btn btn-outline-secondary rounded-pill shadow-sm" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withAuthenticationRequired(View, {
  onRedirecting: () => (<Loading />)
});
