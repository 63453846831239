import axios from 'axios'
import db from '../../utils/dexie-provider.js'

import store from '../store'
import { increment, decrement } from '../slices/syncSlice'

// import { nanoid } from 'nanoid'
// import dayjs from 'dayjs'

// getPlans
// addPlan (?)
// editPlan (?)

export function getPlans() {
  console.log('getting all plans')
  axios.get('/api/v1/plans')
  .then(res => {
    if(res.status === 200) {
      res.data.forEach(function (obj, index) {


        // lklklk should check here if the updatedAt has changed
        // if so, update it and grab the background plan
        // otherwise maybe leave it?
        // you will want to enable this in case it's a routine sync
        // but the files on the server haven't changed

        if(obj.url) {
          async function downloadBg() {
            store.dispatch(increment())

            let fileType = obj.url.split(/[#?]/)[0].split('.').pop().trim();
            let image = await axios.get(obj.url, {responseType: 'arraybuffer'});
            let returnedB64 = Buffer.from(image.data).toString('base64');
            obj.base64 = 'data:image/'+fileType+';base64,'+returnedB64;

            await db.plans.put(obj, obj.appId).then(function() {
              // console.log('plan added')
              store.dispatch(decrement())

            }).catch(function(error) {
               alert ("Unable to save plan locally. Contact support.\nError: " + error);
            });
          }

          downloadBg();
        } else {
          // lklklk, what do we want to do if there isn't a background image?
        }
      });

    } else {
      console.log('error')
    }
  }).catch(error => {
    console.log(error)
    alert('Error getting data. Contact support.')
  })
}

export function addPlan(data) {
  console.log('adding a new plan', data)

  // const id = nanoid();
  // // const siteCode = 'smf1'
  // const siteCode = id;
  // const createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
  //
  // db.sites.put({
  //   id,
  //   siteCode,
  //   createdAt,
  //   dirty: 1
  // }).then (function(){
  //   // Then when data is stored, read from it
  //   return db.sites.get(id);
  // }).then(function (site) {
  //   console.log('reading it from db wihtin the slice: ')
  //   console.log(site)
  // }).catch(function(error) {
  //    alert ("Unable to add site. Contact support.\nError: " + error);
  // });

}

export function editPlan(data) {
  console.log('editing a new plan', data)
}
