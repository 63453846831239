import { createSlice } from '@reduxjs/toolkit'

// shown is a toggle for if the sync modal is visible
// closed is if the user manually closed it, so it doesn't force open again

// future feature:
// think about adding table-specific states where you can show which tables
// are pending or synced, maybe like a table view

export const syncSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0,
    shown: false,
    closed: false
  },
  reducers: {
    increment: state => {
      state.value += 1
    },
    decrement: state => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    setShown: (state, action) => {
      state.shown = action.payload
    },
    setClosed: (state, action) => {
      state.closed = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, setShown, setClosed } = syncSlice.actions

export default syncSlice.reducer
