import axios from 'axios'
import db from '../../utils/dexie-provider.js'

import store from '../store'
import { increment, decrement } from '../slices/syncSlice'

// import { nanoid } from 'nanoid'
// import dayjs from 'dayjs'

// getSites
// addSite (?)
// editSite (?)

export function getSites() {
  console.log('getting all sites')
  axios.get('/api/v1/sites')
  .then(res => {
    if(res.status === 200) {
      res.data.forEach(function (obj, index) {
        store.dispatch(increment())

        db.sites.put(obj, obj.appId).then(function(){
          // console.log('site added')
          store.dispatch(decrement())

        }).catch(function(error) {
           alert ("Unable to save site locally. Contact support.\nError: " + error);
        });
      });

    } else {
      console.log('error')
    }
  }).catch(error => {
    console.log(error)
    alert('Error getting data. Contact support.')
  })
}

export function addSite(data) {
  console.log('adding a new site', data)

  // const id = nanoid();
  // // const siteCode = 'smf1'
  // const siteCode = id;
  // const createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
  //
  // db.sites.put({
  //   id,
  //   siteCode,
  //   createdAt,
  //   dirty: 1
  // }).then (function(){
  //   // Then when data is stored, read from it
  //   return db.sites.get(id);
  // }).then(function (site) {
  //   console.log('reading it from db wihtin the slice: ')
  //   console.log(site)
  // }).catch(function(error) {
  //    alert ("Unable to add site. Contact support.\nError: " + error);
  // });

}

export function editSite(data) {
  console.log('editing a new site', data)
}
