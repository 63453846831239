// https://jsfiddle.net/dfahlander/uztpcs9r/
// https://stackoverflow.com/questions/60441016/dexie-js-nested-collections-not-resolving-in-promise-chain
import axios from 'axios'
import Dexie from 'dexie'
import db from '../utils/dexie-provider.js'
import connectivity  from '../utils/connectivity.js'
import { unsignUrl } from '../utils/globals.js'

import dayjs from 'dayjs'
import { nanoid } from 'nanoid'

import { getChecklists }  from './tables/checklists.js'
import { getSites }       from './tables/sites.js'
import { getPlans }       from './tables/plans.js'
import { getInspections } from './tables/inspections.js'
import { getPlots }       from './tables/plots.js'
import { getPhotos }      from './tables/photos.js'
import { getPlotTypes }   from './tables/plot-types.js'

// general notes about the sync steps
// first we save locally to db in saveData or updateData
// then check for connectivity, and push if possible
// if successful upload, we update db record to remove dirty

// general notes for checkDirty:
// we check for conn; if yes, check if anything needs to be synced up
// when done, or if not, check for the last time something was synced up
// if it's been after a certain time, THEN auto-pull down

// FUTURE DEVELOPMENT:
// think about updating the query to only get records that have been created/updated
// since the last sync time outlined in the system table
// also consider BEFORE doing the sync-down if we should delete any stale records

export function checkDirty() {
  const spawn = Dexie.spawn;
  var dirty = 0;
  var newPull = false;
  var lastPullMins = 0;
  const timeToPull = 600; // minutes before we should force a re-sync

  connectivity().then((res) => {
    if(res) {
      spawn(function*() {
        console.log('checking for dirty items');
        let sites       = yield db.sites.where('dirty').equals(1).count();
        let plans       = yield db.plans.where('dirty').equals(1).count();
        let inspections = yield db.inspections.where('dirty').equals(1).count();
        let plotTypes   = yield db.plotTypes.where('dirty').equals(1).count();
        let plots       = yield db.plots.where('dirty').equals(1).count();
        let photos      = yield db.photos.where('dirty').equals(1).count();
        dirty           = sites + plans + inspections + plotTypes + plots + photos;
        console.log('number of dirty items: ',dirty);

        // check to see how long it's been since we grabbed data
        let lastPull = yield db.system.get('last pull');
        if(lastPull && lastPull.value) {
          lastPull = dayjs(lastPull.value);
          let nowDate = dayjs();
          lastPullMins = nowDate.diff(lastPull, 'minute')
          if(lastPullMins >= timeToPull) newPull = true;
        } else {
          newPull = true;
        }

      }).then(function() {
        if(dirty > 0) {
          console.log('yes dirty, need to sync data');
          syncAllData();
        } else {
          console.log('no dirty records. NOT automatically pulling to save data')
          console.log('new pull:'+newPull)
          // console.log('no dirty records, pull new data?', newPull, lastPullMins+' - mins since');
          // if(newPull) getData();
        }

      }).catch(function(e) {
        alert('Error syncing data. Contact support.'); // lklklk
        console.error("Failed: " + e);
      });
    }
  })
}

export function getData() {
  console.log('pulling data from server')
  connectivity().then((res) => {
    if(res) {
      getChecklists();
      getSites();
      getPlans();
      getInspections();
      getPlotTypes();
      getPlots();
      getPhotos();

      let sysObj = {
        name: 'last pull',
        value: dayjs().format('YYYY-MM-DD HH:mm')
      }

      db.system.put(sysObj, sysObj.name).then(function(){
        console.log('system: last pull updated')
      }).catch(function(error) {
         alert("Unable to finish getting data. Contact support.\nError: " + error);
      });
    } else {
      alert("You are not connected to the internet.\n\nPlease connect to get data.")
    }
  })
}

// this is the one to spin through and sync all tables (like on a refresh/manual sync)
export function syncAllData() {
  const spawn = Dexie.spawn;
  console.log('we are syncing all data');

  // lklklk add the spinner here and/or the overlay to show we are mid-sync

  connectivity().then((res) => {
    if(res) {
      spawn(function*() {
        console.log('checking for dirty items');
        // could also add sites, plans, and plot types
        let inspections = yield db.inspections.where('dirty').equals(1).toArray();
        let plots       = yield db.plots.where('dirty').equals(1).toArray();
        let photos      = yield db.photos.where('dirty').equals(1).toArray();


        // lklklk
        // think about looking into this:
        // https://masteringjs.io/tutorials/fundamentals/foreach-break
        // to avoid it hanging if they lose internet mid sync
        // this way maybe we don't have to check for conn each time?


        // lklklk you also may need to break these up
        // in case it overloads the phone; like wait until each one completes
        // before moving to the next one

        if(inspections.length > 0) {
          console.log('syncing inspections')
          inspections.forEach(function (obj, index) {
            pushData('inspections',obj)
          })
        }

        if(plots.length > 0) {
          console.log('syncing plots')
          plots.forEach(function (obj, index) {
            pushData('plots',obj)
          })
        }

        if(photos.length > 0) {
          console.log('syncing photos')
          photos.forEach(function (obj, index) {
            pushPhoto('photos',obj)
          })
        }


      }).then(function() {

         console.log("All Sync Complete");
         // you would remove the spinner here

      }).catch(function(e) {
        alert('Error syncing all data. Contact support.'); // lklklk
        console.error("Failed: " + e);
      });


    } else {
      // lklklk can you switch this to a modal instead of an alert?
      alert('You aren\'t currently connected to the internet.\nPlease connect this device to the internet and try to sync again.');
    }
  })
}

// this should be the one for a single piece of data
export function saveData(tbl,obj) {
  // only create appId if the obj didn't send it
  if(!obj.appId) obj.appId = nanoid();
  obj.createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
  obj.dirty = 0; // temporarily marking as clean so we don't flash the yellow dot

  console.log('pushing single obj: ', obj)

  db[tbl].put(obj, obj.appId).then(function() {
    console.log('record added to local db')
    connectivity().then((res) => {
      if(res) {
        if(tbl === 'photos')
          pushPhoto(tbl,obj)
        else
          pushData(tbl,obj)
      } else {
        db[tbl].update(obj.appId, {dirty: 1});
      }
    })

  }).catch(function(error) {
     alert ("Unable to save data locally. Contact support.\nError: " + error);
  });
}

export function updateData(tbl,obj) {
  obj.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');

  console.log('updating single obj')
  console.log(obj)

  // these scripts check if the record exists before clobbering
  db[tbl].update(obj.appId, obj).then(function() {
    console.log('record added to local db')
    connectivity().then((res) => {
      if(res) {
        pushData(tbl,obj)
      } else {
        db[tbl].update(obj.appId, {dirty: 1});
      }
    })

  }).catch(function(error) {
     alert ("Unable to save data locally. Contact support.\nError: " + error);
  });
}

export function pushData(tbl,obj) {
  let apiUrl = '';

  // these scripts check if the record exists before clobbering
  if(tbl === 'sites') apiUrl = '/api/v1/site';
  else if(tbl === 'inspections') apiUrl = '/api/v1/inspection';
  else if(tbl === 'plots') apiUrl = '/api/v1/plot';

  // if the inspection is completed, send them an email
  if(tbl === 'inspections' && obj.inspectionStatus==='Complete') {
    axios.post('/api/v1/inspection/send', obj)
    .then(res => {
      if(res.status === 200)
        console.log('email sent')
      else
        console.log('email not sent')
    }).catch(error => {
      console.log(error)
    })
  }

  axios.post(apiUrl, obj)
  .then(res => {
    if(res.status === 200) {
      db[tbl].update(obj.appId, {dirty: 0});
    } else {
      db[tbl].update(obj.appId, {dirty: 1});
    }
  }).catch(error => {
    console.log(error)

    // maybe add something here if they've lost connection?

    // alert('Error syncing data to cloud. Contact support.')
    // this means there was a server error; since we are auto-saving locally
    // do we even need to warn them here? or just leave it dirty to check later?
    db[tbl].update(obj.appId, {dirty: 1});
  })
}

export function pushPhoto(tbl,obj) {
  console.log(obj)

  if(!obj.fileType) obj.fileType = 'image/jpeg';

  axios.post('/api/v1/presign', {
    bucket: 'jbl-icp',
    fileName: obj.fileName,
    fileType: obj.fileType
  })
  .then(res => {
    if(res.status === 200 && res.data) {
      let presignedPostUrl = res.data;

      // convert base64 to blob for uploading to s3
      fetch(obj.base64).then(res => res.blob())
      .then(blob => {
        // console.log(blob)

        axios.put(presignedPostUrl, blob, {
          headers: {
            'Content-Type': obj.fileType, // was buffer in the other script, maybe this isnt needed?
            'Content-Encoding': 'base64'
          },
        })
        .then(res => {
          if(res.status === 200) {
            // photo successfully uploaded
            let formData = obj;
            delete formData.base64;

            // update the url
            formData.status = 'A';
            formData.url = unsignUrl(res.config.url);

            axios.post('/api/v1/photo', formData)
            .then(res => {
              if(res.status === 200) {
                // lklklk this is where the call would be to update the local photo record
                // and mark it as successfully synced
                // also add in the file size if we want to check it and delete it locally?
                db.photos.update(obj.appId, {dirty: 0});
              } else {
                console.log('error')
                db.photos.update(obj.appId, {dirty: 1});
              }
            }).catch(error => {
              console.log(error)
              alert('Error saving photo record to server. Contact support.')
              db.photos.update(obj.appId, {dirty: 1});
            })

          } else {
            console.log(res)
            alert('Error uploading photo to s3. Contact support.')
            db.photos.update(obj.appId, {dirty: 1});
          }
        }).catch(error => {
          console.log(error)
          alert('Error uploading photo. Contact support.')
          db.photos.update(obj.appId, {dirty: 1});
        })

      })

    } else {
      console.log('error getting presign url');
      db.photos.update(obj.appId, {dirty: 1});

    }
  })
  .catch(error => {
    console.log(error)
    db.photos.update(obj.appId, {dirty: 1});
  })
}
