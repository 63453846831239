import axios from 'axios'
import db from '../../utils/dexie-provider.js'

import store from '../store'
import { increment, decrement } from '../slices/syncSlice'

// import { nanoid } from 'nanoid'
// import dayjs from 'dayjs'

// getPhotos

export function getPhotos() {
  console.log('getting all photos')

  axios.get('/api/v1/photos')
  .then(res => {
    if(res.status === 200) {
      // console.log(res.data)
      res.data.forEach(function (obj, index) {

        // lklklk should check here if the updatedAt has changed
        // if so, update it and grab the photo
        // otherwise maybe leave it?
        // you will want to enable this in case it's a routine sync
        // but the files on the server haven't changed

        if(obj.url) {
          async function downloadPic() {
            store.dispatch(increment())

            let fileType = obj.url.split(/[#?]/)[0].split('.').pop().trim();
            let image = await axios.get(obj.url, {responseType: 'arraybuffer'});
            let returnedB64 = Buffer.from(image.data).toString('base64');
            obj.base64 = 'data:image/'+fileType+';base64,'+returnedB64;

            await db.photos.put(obj, obj.appId).then(function() {
              // console.log('photo added')
              store.dispatch(decrement())

            }).catch(function(error) {
               alert ("Unable to save photo locally. Contact support.\nError: " + error);
            });
          }

          downloadPic();
        }
      });

    } else {
      console.log('error')
    }
  }).catch(error => {
    console.log(error)
    alert('Error getting data. Contact support.')
  })
}
