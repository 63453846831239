import React, { useEffect, useState } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Helmet } from 'react-helmet'
import parse from 'html-react-parser'
import axios from 'axios'

import Loading from './components/Loading'
import { Download, Printer } from 'react-bootstrap-icons'
import { PDFDownloadLink, Document, Page, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fcf0ee',
    fontSize: '14pt',
    lineHeight: '1.3'
  }
})

const MyDoc = (props) => (
  <Document>
    <Page style={styles.page}>
      Hi
    </Page>
  </Document>
);

const View2 = (props) => {

  const inspectionId = props.match.params.inspectionId;
  const [inspectionObj, setInspectionObj] = useState({})
  const [checklistObj, setChecklistObj] = useState({})
  const [error, setError] = useState('');

  useEffect(() => {
    if(inspectionId) {
      console.log(inspectionId)

      axios.get('/api/v1/inspection/'+inspectionId)
      .then(res => {
        if(res.status === 200) {
          setInspectionObj(res.data)


          axios.get('/api/v1/checklists/'+res.data.checklistId)
          .then(res2 => {
            console.log(res2.data)
            if(res2.status === 200)
              setChecklistObj(res2.data)
            else
              setError('Missing checklist');

          }).catch(error => {
            console.log(error)
            setError('Missing checklist. Call support');
          })

        } else {
          setError('Missing inspection');
        }
      }).catch(error => {
        console.log(error)
        setError('Missing inspection. Call support');
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionId])

  const print = () => {
    window.print();
  }

  if(error) {
    return (
      <p>{error}</p>
    )
  }

  return (
    <>
    <Helmet>
      <style type="text/css">{`
        .output p { margin-bottom: 0; }
      `}</style>
    </Helmet>

    <nav className="d-print-none navbar navbar-expand navbar-dark text-white fixed-top bg-dark shadow pt-2" aria-label="Second navbar example">
      <div className="container-fluid">
        <ul className="navbar-nav mx-auto p-1">
          <li className="nav-item">
            ICP: View Inspection
          </li>
        </ul>
      </div>
    </nav>

    <div className="pt-4 d-print-none"></div>

    <div className="container">
      <div className="row">
        <div className="col">
          <Printer className="float-end pointer" size={24} onClick={print} />
          {/*<Download className="float-end pointer me-3" size={24} onClick={print} />*/}
          <h6>{checklistObj.name && checklistObj.name.toUpperCase()}</h6>
          <h1>{inspectionObj.name}</h1>
          <p>Status: {inspectionObj.inspectionStatus}<br />
          Last updated: {inspectionObj.updatedAt}<br />
          Updated by: <a href={'mailto:'+inspectionObj.updatedBy}>{inspectionObj.updatedBy}</a></p>

        {/*
          <PDFDownloadLink document={<MyDoc />} fileName="somename.pdf">
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
          </PDFDownloadLink>
          */}

        {inspectionObj.inspectionNotes && (
          <p>{inspectionObj.inspectionNotes}</p>
        )}

        { checklistObj.schema && checklistObj.schema.map((obj, index) => {
          // console.log(obj)
          return (
            <div key={"form-"+index} className="output">
              {obj.label && (
                <p className="fw-bold">{obj.label}</p>
              )}

              {obj.description && (
                <span className="fw-bold">{parse(obj.description)}</span>
              )}

              {inspectionObj[obj.column]}
              <hr />
            </div>
          )
        })}
        </div>
      </div>
    </div>
    </>
  )
}

export default withAuthenticationRequired(View2, {
  onRedirecting: () => (<Loading />)
});
