import React, { useCallback, useEffect, useState } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import {Helmet} from 'react-helmet'

import Dexie from 'dexie'
import db from '../utils/dexie-provider.js'
import { useLiveQuery } from 'dexie-react-hooks'

import Loading from './components/Loading'
import MapView  from './components/Map'
import FormView from './components/Form'
import MapKey  from './components/MapKey'

import { Modal } from 'bootstrap'
import { ArrowLeftShort, Key } from 'react-bootstrap-icons'

const View = (props) => {
  let history = useHistory();
  const spawn = Dexie.spawn;
  const siteId = props.match.params.siteId;
  const inspectionId = props.match.params.inspectionId;
  const [siteObj, setSiteObj] = useState({})
  const [planObj, setPlanObj] = useState({})
  const [checklistObj, setChecklistObj] = useState({})
  const [inspectionObj, setInspectionObj] = useState({})
  const [formTrigger, setFormTrigger] = useState({})
  const [error, setError] = useState('');

  const handleOnClick = useCallback(() => {
    history.goBack()
  }, [history]);

  let mapType = 'setup';
  let titleText = 'Setup';
  if(inspectionId) {
    mapType = 'inspection';
    titleText = 'Inspection';
  }

  let title = siteObj.siteCode ? siteObj.siteCode : 'TBD';
  const plotTypes  = useLiveQuery(() => db.plotTypes.toArray(), []);

  useEffect(() => {
    if(siteId) {
      spawn(function*() {
        let siteData  = yield db.sites.get(siteId);
        let sitePlans = yield db.plans.where('siteId').equals(siteId).toArray();
        if(siteData !== undefined)
          setSiteObj(siteData)

        // lklklk getting the plan background from here
        // might need to sort this or get a "default" and then let them pick between multiple
        if(sitePlans !== undefined) {
          setPlanObj(sitePlans[0]);
        }

        if(inspectionId) {
          let inspectionData  = yield db.inspections.get(inspectionId);
          let checklistData   = yield db.checklists.get(inspectionData.checklistId);
          console.log('inspectionData: ',inspectionData)
          console.log('checklistData: ',checklistData)

          setChecklistObj(checklistData)
          setInspectionObj(inspectionData)
        }

      }).then(function() {
         // finished loading plan information

      }).catch(function(e) {
        console.log('Error loading site ID')
        setError('Missing site');
      });
    }
  }, [spawn, siteId, inspectionId])



  // this file should have the filters and the plan toggles...?

  // receives from this page AND map child compoenent, sends to form child
  const showModal = (modalId, data) => {
    if(modalId === 'formModal')
      setFormTrigger(data);

    var bsModal = Modal.getOrCreateInstance(document.getElementById(modalId))
    bsModal.show();
  };

  return (
    <>
      <Helmet>
        <title>{title} | {titleText} | ICP</title>
      </Helmet>

      <div className="d-flex flex-column align-self-stretch vh-100">
        <nav className="navbar navbar-expand navbar-dark text-white fixed-top bg-dark shadow pt-2" aria-label="Second navbar example">
          <div className="container-fluid">
            <ArrowLeftShort className="mr-auto pointer" size={35} onClick={handleOnClick} />
            <ul className="navbar-nav mx-auto">
              <li className="nav-item pointer" onClick={() => history.push('/')}>
                {title}
              </li>
            </ul>
            <div></div>
            <Key className="pointer me-3" size={25} onClick={() => showModal('keyModal', null)} />
            {/*<JournalText className="pointer" size={25} onClick={() => history.push('/'+siteObj.siteCode+'/swppp')} />*/}
          </div>
        </nav>

        <div className="flex-grow-1">
          <MapView siteId={siteId} plan={planObj} plotTypes={plotTypes} inspectionId={inspectionId} mapEl={mapType} showModal={showModal} />
        </div>
      </div>

      <FormView site={siteObj} plan={planObj} plotTypes={plotTypes} checklist={checklistObj} inspection={inspectionObj} mapEl={mapType} formTrigger={formTrigger} />
      <MapKey plotTypes={plotTypes} />

      { error==='Missing site' && (
        <p>Site not found.</p>
      )}
    </>
  )
}

export default withAuthenticationRequired(View, {
  onRedirecting: () => (<Loading />)
});
