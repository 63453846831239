import React, { useEffect, useState } from 'react'
import Dexie from 'dexie'
import db from '../../utils/dexie-provider.js'

import { useForm } from 'react-hook-form'
import { saveData, updateData }  from '../../data/sync.js'
import FormBlock from './FormBlock'
import handlePhoto from '../../utils/handle-photo'
import { drawPlot }    from '../../utils/map-plot'

import { nanoid } from 'nanoid'
import { Collapse, Modal } from 'bootstrap'
import { ArrowLeftShort, Camera, Images } from 'react-bootstrap-icons'

const Component = (props) => {
  const spawn = Dexie.spawn;
  const siteObj = props.site;
  const planObj = props.plan;
  const plotTypes = props.plotTypes;
  const checklistObj = props.checklist;
  const inspectionObj = props.inspection;
  const formTrigger = props.formTrigger;
  const { register, handleSubmit, formState: { errors }, clearErrors, setValue, getValues, reset } = useForm();

  const modalId = 'formModal';
  const mapEl = props.mapEl;

  let title = siteObj.siteCode ? siteObj.siteCode : 'TBD';
  let inspectionId = inspectionObj.appId ? inspectionObj.appId : null;

  const [submitType, setSubmitType] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [plotPhotos, setPlotPhotos] = useState([]);
  const [parentData, setParentData] = useState([]);
  const [parentPhotos, setParentPhotos] = useState([]);

  const onSubmit = data => {
    const formData = data;

    // add automatic fields
    formData.status = 'A';
    formData.siteId = siteObj.appId;
    formData.planId = planObj.appId;
    formData.inspectionId = inspectionObj.appId;
    formData.xCoord = parseInt(formData.xCoord);
    formData.yCoord = parseInt(formData.yCoord);

    console.log(formData)

    // if we aren't in an inspection, do the update automatically
    // if we are in the inspection, check if the plotId exists
      // if it does, we need to delete and update the PARENT plot
      // if it doesn't, it's a triangle and we may need to add so should do this
    let canvas = document.getElementById(mapEl).fabric;

    if( Object.keys(inspectionObj).length === 0) {
      console.log('updating the map (setup)')
      canvas.getObjects().forEach(function(o) {
        if(o.appId === formData.appId) canvas.remove(o);
      })
      let display = drawPlot(plotTypes, inspectionId, planObj, formData, null);
      canvas.add(display);
      canvas.renderAll();

    } else if(formData.plotId) {
      console.log('updating the map (permanent asset)')
      canvas.getObjects().forEach(function(o) {
        if(o.appId === formData.plotId) canvas.remove(o);
      })

      // need to get the parent thing to redraw, and then formData is the child record?
      spawn(function*() {
        let parentPlot = yield db.plots.get({appId: formData.plotId});
        let display = drawPlot(plotTypes, inspectionId, planObj, parentPlot, formData);
        canvas.add(display);
        canvas.renderAll();
      }).catch(function(e) {
        console.log('Error refreshing after form update')
        console.log(e)
      });

    } else if (!formData.plotId) {
      console.log('updating the map (inspection note)')
      canvas.getObjects().forEach(function(o) {
        if(o.appId === formData.appId) canvas.remove(o);
      })
      let display = drawPlot(plotTypes, inspectionId, planObj, formData, null);
      canvas.add(display);
      canvas.renderAll();
    }

    if(submitType === 'add')
      saveData('plots',formData);
    else
      updateData('plots',formData);

    hideModal();
  }

  const hideModal = () => {
    var bsModal = Modal.getOrCreateInstance(document.getElementById(modalId))
    bsModal.hide();
  }

  const modalHidden = () => {
    reset();
    clearErrors();
    setSelectedType(null);
    setParentData([]);
    setPlotPhotos([]);
    setParentPhotos([]);
  }

  const toggleCollapse = (btn, id) => {
    var bsCollapse = Collapse.getOrCreateInstance(document.getElementById(id))
    bsCollapse.toggle();
    if(btn) btn.blur();
  }

  useEffect(() => {
    if(!formTrigger) return;
    console.log('--------------');
    console.log('formTrigger: ',formTrigger)

    if(Object.keys(inspectionObj).length === 0) {
      //  ___      _
      // / __| ___| |_ _  _ _ __
      // \__ \/ -_)  _| || | '_ \
      // |___/\___|\__|\_,_| .__/
      //                   |_|

      if(formTrigger.type === 'add') {
        console.log('in setup, adding plot')
        setSubmitType('add')
        setValue('appId', nanoid())
        setValue('plotName', formTrigger.plotName)
        setValue('xCoord', formTrigger.left)
        setValue('yCoord', formTrigger.top)

      } else if(formTrigger.type === 'edit') {
        console.log('in setup, editing plot')
        setSubmitType('update')
        spawn(function*() {
          let plotData   = yield db.plots.get(formTrigger.appId);
          let plotPhotos = yield db.photos.where('plotId').equals(formTrigger.appId).toArray();
          setSelectedType(plotData.typeId)
          setPlotPhotos(plotPhotos);

          for (const [key, value] of Object.entries(plotData)) {
            setValue(key, value);
          }

        }).then(function() {
           // console.log("Complete");

        }).catch(function(e) {
          console.log('Error loading plot data')
        });
      }

    } else {
      //  ___                      _   _
      // |_ _|_ _  ____ __  ___ __| |_(_)___ _ _
      //  | || ' \(_-< '_ \/ -_) _|  _| / _ \ ' \
      // |___|_||_/__/ .__/\___\__|\__|_\___/_||_|
      //             |_|

      if(formTrigger.type === 'add') {
        console.log('in inspection, adding note')
        setSubmitType('add')
        setSelectedType('default')
        setValue('appId', nanoid())
        setValue('plotName', formTrigger.plotName)
        setValue('xCoord', formTrigger.left)
        setValue('yCoord', formTrigger.top)

      } else if(formTrigger.type === 'edit') {

        spawn(function*() {
          let plotData   = yield db.plots.get(formTrigger.appId);
          let plotPhotos = yield db.photos.where('plotId').equals(formTrigger.appId).toArray();
          console.log('plotData: ',plotData)

          if(plotData.inspectionId) {
            console.log('in inspection, editing note')
            setSubmitType('update')
            setSelectedType('default')
            setPlotPhotos(plotPhotos);

            for (const [key, value] of Object.entries(plotData)) {
              setValue(key, value);
            }

          } else {
            // check if there are any child records with this appId = plotId and inspectionId
            // if it exists, we are EDITING an inspection plot
            // if not, we are ADDING an inspection plot

            setSelectedType(plotData.typeId)
            setParentData(plotData);
            setParentPhotos(plotPhotos);
            setValue('plotId', plotData.appId)

            let childData   = yield db.plots.get({plotId: plotData.appId});
            let childPhotos = yield db.photos.where('plotId').equals(childData.appId).toArray();
            console.log('HEREEEEE')
            if(childData) {
              console.log('in inspection, editing inspection plot')
              console.log('childData: ',childData)
              setSubmitType('update')
              setValue('appId', childData.appId)
              setPlotPhotos(childPhotos);

              for (const [key, value] of Object.entries(childData)) {
                if(key.includes('udf') || key.includes('arr'))
                  setValue(key, value);
              }

            } else {
              console.log('in inspection, adding inspection plot')
              setSubmitType('add')
              // intentionally leaving plotName, xCoord, yCoord blank
              // so it's easier to spot in the database which records are inspection vs setup
              setValue('appId', nanoid())

            }
          }

        }).then(function() {
           // console.log("Complete");

        }).catch(function(e) {
          console.log('Error loading plot data')
          // console.log(e)
        });
      }
    }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formTrigger])

  useEffect(() => {
    let myModalEl = document.getElementById(modalId)
    myModalEl.addEventListener('hidden.bs.modal', modalHidden);

    return () => {
      myModalEl.removeEventListener('hidden.bs.modal', modalHidden);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleImageChange = (e) => {
    let files = e.target.files;
    if(files && files.length > 0) {
      let fileArr = [...files];

      fileArr.forEach(function (file, index) {
        // file, s3 bucket name, desired prefix, siteId, planId, plotId, inspectionId
        handlePhoto(file, 'site-photo', siteObj.appId, planObj.appId, getValues('appId'), inspectionId, function() {
          spawn(function*() {
            let currentAppId = getValues('appId');
            console.log(currentAppId)
            let plotPhotos = yield db.photos.where('plotId').equals(currentAppId).toArray();
            setPlotPhotos(plotPhotos);
          }).then(function() {
             console.log("Photos finished");

          }).catch(function(e) {
            console.log('Error loading plan photos')
          });
        });
      });
    }
  }

  const deletePlot = () => {
    console.log('deleting plot')
    let appId = getValues('appId');
    console.log(appId)
    let formData = {
      appId,
      status: 'D'
    };

    // set status to D
    updateData('plots',formData);

    // remove from canvas
    let canvas = document.getElementById(mapEl).fabric;
    canvas.getObjects().forEach(function(o) {
      if(o.appId === formData.appId)
        canvas.remove(o);
    })

    hideModal();
  }

  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={modalId+'Label'} aria-hidden="true">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content bg-tan">
          <div className="modal-header p-2 justify-content bg-blue text-white">
            <ArrowLeftShort className="pointer" size={30} onClick={hideModal} />
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                ICP: {title}
              </li>
            </ul>
            <div></div>
          </div>
          <div className="modal-body">
            <h4 className="modal-title mb-2" id="initialModalLabel">
              { Object.keys(inspectionObj).length === 0 && formTrigger.type === 'edit' && ( <span>Edit Plot</span>)}
              { Object.keys(inspectionObj).length === 0 && formTrigger.type === 'add'  && ( <span>Add Plot</span>)}
              { Object.keys(inspectionObj).length > 0 && formTrigger.type === 'add'  && ( <span>Add Note</span>)}
              { parentData.length===0 && Object.keys(inspectionObj).length > 0 && formTrigger.type === 'edit'  && ( <span>Edit Note</span>)}

              { parentData && parentData.plotName && selectedType && plotTypes.filter(type => type.appId === selectedType).map((type, index) => {
                console.log(type)
                return (
                  <span key={'title'+index}>{String(parentData.plotName).padStart(3, '0')}: {type.name}</span>
                )
              })}

            </h4>

            <div className="row">
              { parentPhotos && parentPhotos.length > 0 && (
                <div className="col-sm-6">
                  <h6>Reference Photos</h6>
                  <div className="horizontal-scroll mb-3">
                    { parentPhotos && parentPhotos.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt))).map((obj, index) => (
                        <img key={'opt'+index} src={obj.base64} className="horizontal-thumb border" alt="Upload" />
                    ))}
                  </div>
                </div>
              )}

              { plotPhotos && plotPhotos.length > 0 && (
                <div className="col-sm-6">
                  <h6>Inspection Photos</h6>
                  <div className="horizontal-scroll mb-3">
                    { plotPhotos && plotPhotos.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt))).map((obj, index) => (
                      <img key={'opt'+index} src={obj.base64} className="horizontal-thumb border" alt="Upload" />
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="mb-3">
              <button type="button" className="file-container btn btn-light shadow-sm rounded-pill px-3 me-3">
                <Camera className="pointer me-2" size={18} />
                <span>Take Photo</span>
                <input type="file" accept="image/*" capture="environment" onChange={handleImageChange} />
              </button>
              <button type="button" className="file-container btn btn-light shadow-sm rounded-pill px-3">
                <Images className="pointer me-2" size={18} />
                <span>Camera Roll</span>
                <input type="file" accept="image/*" onChange={handleImageChange} />
              </button>
            </div>

            { !formTrigger && (
              <p>Need form type.</p>
            )}

            { formTrigger && (
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="mt-3 mb-5 pb-4">

                { Object.keys(inspectionObj).length === 0 && (
                  <>
                  <div className="form-group">
                    <label>Select Type</label>
                    <select id="typeId"
                      className="form-select shadow-sm"
                      aria-label="Default select example"
                      {...register("typeId", {required: true})}
                      onChange={e => {
                        clearErrors('typeId')
                        setSelectedType(e.target.value)
                      }}
                    >
                      <option value=""></option>
                      {plotTypes && plotTypes.sort((a, b) => (a.name > b.name) ? 1 : -1).map((obj, index) => (
                        <option key={'opt'+index.toString()} value={obj.appId}>{obj.name}</option>
                      ))}
                    </select>
                    {errors.typeId && <div className="error">Please select a plot type</div>}
                  </div>

                  { selectedType && plotTypes.filter(type => type.appId === selectedType).map((type, index) => {
                    return (
                      <div key={'setup-'+index}>
                        { type.schema && type.schema.map((obj, index) =>
                          <FormBlock key={"form-"+index} obj={obj} register={register} errors={errors} clearErrors={clearErrors} setValue={setValue} />
                        )}
                      </div>
                    )
                  })}

                  {formTrigger.type === 'edit' && (
                    <div className="mt-4">
                      <div className="collapse show" id="deletePlot">
                        <button className="btn btn-sm btn-outline-danger" type="button"
                          onClick={(e) => {
                            toggleCollapse(e.target, 'deletePlot');
                            toggleCollapse(null, 'confirmDeletePlot');
                          }} >
                            Delete Plot
                        </button>
                      </div>

                      <div className="collapse" id="confirmDeletePlot">
                        <p className="mb-1">Are you sure you want to delete this plot?</p>
                        <button className="btn btn-sm btn-danger me-2" type="button" onClick={(e) => deletePlot()} >Yes</button>
                        <button className="btn btn-sm btn-outline-secondary" type="button"
                          onClick={(e) => {
                            toggleCollapse(e.target, 'deletePlot');
                            toggleCollapse(null, 'confirmDeletePlot');
                          }} >
                            No
                        </button>
                      </div>
                    </div>
                  )}
                  </>
                )}


                { Object.keys(inspectionObj).length > 0 && (
                  <>
                  { selectedType === 'default' && checklistObj.default && checklistObj.default.map((obj, index) =>
                    <FormBlock key={"default-"+index} obj={obj} register={register} errors={errors} clearErrors={clearErrors} setValue={setValue} />
                  )}

                  { selectedType !== 'default' && plotTypes.filter(type => type.appId === selectedType).map((type, index) => (
                    <div key={'inspection-'+index}>
                      { type.plotSchema && type.plotSchema.map((obj, index) => {
                        // if this is a dynamic field, pull
                        if(obj.type.includes('dynamic-text')) {
                          if(obj.linked in parentData && parentData[obj.linked])
                            obj.value = parentData[obj.linked];
                        } else if(obj.type.includes('dynamic-checklist')) {
                          obj.options = []
                          if(obj.linked in parentData && parentData[obj.linked]) {
                            // check if the parentData value is a string or array
                            if (typeof parentData[obj.linked] === 'string' || parentData[obj.linked] instanceof String)
                              obj.options.push({value: parentData[obj.linked]})
                            else {
                              parentData[obj.linked].forEach(function (item, index) {
                                obj.options.push({value: item})
                              });
                            }
                          }
                        }

                        return (
                          <FormBlock key={"form-"+index} obj={obj} register={register} errors={errors} clearErrors={clearErrors} setValue={setValue} />
                        )
                      })}

                      {/* should we add the delete button here for inspection plots? */}
                    </div>
                  ))}
                  </>
                )}

                <br /><br />
                <input type="hidden" {...register("plotName")} />
                <input type="hidden" {...register("appId")} />
                <input type="hidden" {...register("plotId")} />
                <input type="hidden" {...register("xCoord")} />
                <input type="hidden" {...register("yCoord")} />
                <button id="save-btn" type="submit" className="btn btn-success rounded-pill shadow-sm" onClick={e => e.target.blur()}>
                  {(submitType === 'add') ? 'Save' : 'Save Changes'}
                </button>
              </form>
            )}

          </div>
        </div>
      </div>
    </div>
  );
}

export default Component;
