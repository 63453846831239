import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { saveData }  from '../../data/sync.js'

import dayjs from 'dayjs'
import { Modal } from 'bootstrap'


const Component = (props) => {
  const { register, handleSubmit, formState: { errors }, clearErrors, reset } = useForm();
  const modalId = 'addNewModal';

  const onSubmit = data => {
    const formData = data;
    const timestamp = dayjs().format('MMM D, HH:mma');

    // add automatic fields
    let found = props.sites.find(site => site.appId === formData.siteId);
    formData.name = found.siteCode + ' - '+ timestamp;
    formData.status = 'A';
    formData.inspectionStatus = 'Pending';

    saveData('inspections',formData);
    hideModal();
  }

  const hideModal = () => {
    var bsModal = Modal.getOrCreateInstance(document.getElementById(modalId))
    bsModal.hide();
  }

  const modalHidden = () => {
    console.log('hiding modal')
    reset();
    clearErrors();
  }

  useEffect(() => {
    let myModalEl = document.getElementById(modalId)
    myModalEl.addEventListener('hidden.bs.modal', modalHidden);

    return () => {
      myModalEl.removeEventListener('hidden.bs.modal', modalHidden);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={modalId+'Label'} aria-hidden="true">
      <div className="modal-dialog modal-sm">
        <div className="modal-content bg-tan">
          <div className="modal-body">
            <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
            <h5 className="modal-title" id={modalId+'Label'}>Add New</h5>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="mt-3">
              <div className="form-group">
                <label>Select Site</label>
                <select id="siteId" className="form-select shadow-sm"
                  aria-label="Default select example"
                  {...register("siteId", {required: true})}
                >
                  <option value=""></option>
                  { props.sites && props.sites.length > 0 && props.sites.filter(obj => obj.status === 'A').map((obj, index) =>
                    <option key={'site-'+index} value={obj.appId}>{obj.siteCode}</option>
                  )}
                </select>
                {errors.siteId && <div className="error">Please select site</div>}
              </div>

              <div className="form-group mt-3">
                <label>Select Checklist</label>
                <select id="checklistId" className="form-select shadow-sm"
                  aria-label="Checklist Type"
                  {...register("checklistId", {required: true})}
                >
                  <option value=""></option>
                  { props.checklists && props.checklists.length > 0 && props.checklists.sort((a, b) => (a.name > b.name) ? 1 : -1).filter(obj => obj.status === 'A').map((obj, index) =>
                    <option key={'checklist-'+index} value={obj.appId}>{obj.name}</option>
                  )}
                </select>
                {errors.checklistId && <div className="error">Please select checklist type</div>}
              </div>

              <div className="clearfix mt-4 mb-1">
                <button type="submit" className="float-end ms-2 btn btn-success rounded-pill shadow-sm" onClick={e => e.target.blur()}>Add</button>
                <button type="button" className="float-end btn btn-outline-secondary rounded-pill shadow-sm" data-bs-dismiss="modal">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Component;
