import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'

import db from './utils/dexie-provider.js'
import { useLiveQuery } from 'dexie-react-hooks'
import { checkDirty, syncAllData } from './data/sync.js'

import { useSelector, useDispatch } from 'react-redux'
import { setShown, setClosed } from './data/slices/syncSlice'

// import Dynamic from "./views/Dynamic";
import Inspections from "./views/Inspections";
import Inspection from "./views/Inspection";
import Plan       from "./views/Plan";
import Swppp      from "./views/Swppp";
// import Initial     from "./views/inspections/Initial";
// import Recurring   from "./views/inspections/Recurring";

import ViewInspection from "./views/ViewInspection";

import { Modal } from 'bootstrap'

// animated transitions?
// https://reactrouter.com/web/example/animated-transitions

function App() {
  const [dirtyQty, setDirtyQty] = useState('');
  const dirtySites       = useLiveQuery(() => db.sites.where('dirty').equals(1).count());
  const dirtyPlans       = useLiveQuery(() => db.plans.where('dirty').equals(1).count());
  const dirtyInspections = useLiveQuery(() => db.inspections.where('dirty').equals(1).count());
  const dirtyPlotTypes   = useLiveQuery(() => db.plotTypes.where('dirty').equals(1).count());
  const dirtyPlots       = useLiveQuery(() => db.plots.where('dirty').equals(1).count());
  const dirtyPhotos      = useLiveQuery(() => db.photos.where('dirty').equals(1).count());

  const syncCount  = useSelector(state => state.sync.value)
  const syncShown  = useSelector(state => state.sync.shown)
  const syncClosed = useSelector(state => state.sync.closed)
  const dispatch = useDispatch()

  useEffect(() => {
    checkDirty();
  }, [])

  useEffect(() => {
    // console.log(syncCount)
    if(syncCount > 0) {
      if(!syncShown && !syncClosed) {
        dispatch(setShown(true))
        showModal('syncModal');
      }

    } else {
      // this is a hack; waiting 1 second after sync to ensure the modal closes
      setTimeout(function(){
        hideModal('syncModal');
        dispatch(setShown(false))
        dispatch(setClosed(false))
      }, 500)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncCount])

  useEffect(() => {
    let qty = dirtySites + dirtyPlans + dirtyInspections + dirtyPlotTypes + dirtyPlots + dirtyPhotos;
    (qty > 0) ? setDirtyQty(qty) : setDirtyQty('');
  }, [dirtySites, dirtyPlans, dirtyInspections, dirtyPlotTypes, dirtyPlots, dirtyPhotos])

  const showModal = (modalId) => {
    var modalEl = document.getElementById(modalId)
    var bsModal = Modal.getOrCreateInstance(modalEl)
    bsModal.show();
    modalEl.addEventListener('hide.bs.modal', modalHidden)
  }

  const hideModal = (modalId) => {
    var modalEl = document.getElementById(modalId)
    var bsModal = Modal.getOrCreateInstance(modalEl)
    bsModal.hide();
    modalEl.removeEventListener('hide.bs.modal', modalHidden)
  }

  const modalHidden = () => {
    dispatch(setShown(false))
    dispatch(setClosed(true))
    var modalEl = document.getElementById('syncModal')
    modalEl.removeEventListener('hide.bs.modal', modalHidden)
  }

  return (
    <>
      <Helmet>
        <title>ICP</title>
      </Helmet>

      <Switch>
        <Route exact={true} path="/site/:siteId" render={(props) => (
          <Plan {...props} />
        )} />

        <Route path="/inspection/site/:siteId/:inspectionId" render={(props) => (
          <Plan {...props} />
        )} />

        <Route exact path="/inspection/view/:inspectionId" render={(props) => (
          <ViewInspection {...props} />
        )} />

        <Route path="/inspection/:siteId/:inspectionId" render={(props) => (
          <Inspection {...props} />
        )} />

        <Route path="/:siteId/swppp" render={(props) => (
          <Swppp {...props} />
        )} />

        <Route render={(props) => ( <Inspections {...props} /> )} />
      </Switch>

      <div id="unsynced" className="badge rounded-pill bg-warning text-dark shadow-sm"  onClick={e => syncAllData() }>{dirtyQty}</div>

      <div className="modal fade" id="syncModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="syncModal" aria-hidden="true">
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="syncModal">
                <div className="spinner-border text-secondary spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                &nbsp; Sync in Progress
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">
              <h1 className="display-1">{syncCount}</h1>
              <p>items left to sync</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      {/*
        <Route exact={true} path="/:site/swppp" render={(props) => (
        <Route exact={true} path="/:site/swppp/bmps" render={(props) => (
        <Route exact={true} path="/:site/swppp/potential-pollutant-sources" render={(props) => (
      */}
    </>
  );
}

// react router: scroll to top on every transition fix:
// https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition

export default App;
