import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Helmet } from 'react-helmet'
import axios from 'axios'

import Loading      from './components/Loading'
import MapViewOnly  from './components/MapViewOnly'
// import MapKey  from './components/MapKey'
import { ArrowLeftShort } from 'react-bootstrap-icons'

const View = (props) => {
  const history  = useHistory();
  const siteCode = props.match.params.siteId;
  const [siteObj, setSiteObj] = useState({})
  const [planObj, setPlanObj] = useState({})
  const [plotTypesArr, setPlotTypesArr] = useState([])
  const [plotsArr, setPlotsArr] = useState([])
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  useEffect(() => {
    if(siteCode) {
      axios.get('/api/v1/site/'+siteCode)
      .then(res => {
        if(res.status === 200) {
          if(res.data !== undefined) setSiteObj(res.data)

          // then get plan information
          axios.get('/api/v1/plan/'+siteCode)
          .then(res => {
            if(res.status === 200) {
              if(res.data !== undefined) setPlanObj(res.data)

            } else {
              console.log('error')
            }
          }).catch(error => {
            console.log(error)
            alert('Error getting data. Contact support.')
          })

          // then get plots
          axios.get('/api/v1/plots/'+siteCode)
          .then(res => {
            if(res.status === 200) {
              if(res.data !== undefined) setPlotsArr(res.data)

            } else {
              console.log('error')
            }
          }).catch(error => {
            console.log(error)
            alert('Error getting data. Contact support.')
          })

        } else {
          console.log('error')
        }
      }).catch(error => {
        console.log(error)
        alert('Error getting data. Contact support.')
      })
    }
  }, [siteCode]);

  useEffect(() => {
    axios.get('/api/v1/plot-types')
    .then(res => {
      if(res.status === 200) {
        if(res.data !== undefined) setPlotTypesArr(res.data)
      } else {
        console.log('error')
      }
    }).catch(error => {
      console.log(error)
      alert('Error getting data. Contact support.')
    })
  }, [siteCode]);

  return (
    <>
    <Helmet>
      <title>{siteCode} | SWPPP</title>
      <style type="text/css">{`
        body {
          padding-bottom: 200px;
        }
      `}</style>
    </Helmet>

    <nav className="navbar navbar-dark text-white fixed-top bg-dark shadow pt-2">
      <div className="container-fluid">
        <ArrowLeftShort className="mr-auto pointer" size={35} onClick={() => history.goBack()} />
        <ul className="navbar-nav mx-auto py-1">
          <li className="nav-item pointer">
            {siteObj.siteCode} SWPPP
          </li>
        </ul>

        <button className="custom-toggler navbar-toggler d-none d-sm-block d-lg-none" type="button" data-toggle="collapse" data-target="#navbarLinks" aria-controls="navbarLinks" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarLinks">
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item"><a href={"/"+siteCode+"/swppp"} className="nav-link link-light">
              Facility Map
            </a></li>
            <li><a href={"/"+siteCode+"/swppp/bmps"} className="nav-link link-light">
              List of BMPs
            </a></li>
            <li><a href={"/"+siteCode+"/swppp/potential-pollutant-sources"} className="nav-link link-light">
              Potential Pollutant Sources
            </a></li>
            <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
              Facility Description and Contact Information
            </a></li>
            <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
              Storm Water Control Measures
            </a></li>
            <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
              Schedules and Procedures for Monitoring
            </a></li>
            <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
              Inspections
            </a></li>
            <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
              Sector-Specific Requirements
            </a></li>
            <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
              SWPPP Certification
            </a></li>
            <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
              SWPPP Modifications
            </a></li>
            <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
              Attachments
            </a></li>
          </ul>
        </div>
      </div>
    </nav>
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="col-sm-3 small lh-sm d-none d-lg-block">
            <ul className="nav nav-pills flex-column mb-auto">
              <li className="nav-item"><a href={"/"+siteCode+"/swppp"} className="nav-link">
                Facility Map
              </a></li>
              <li><a href={"/"+siteCode+"/swppp/bmps"} className="nav-link">
                List of BMPs
              </a></li>
              <li><a href={"/"+siteCode+"/swppp/potential-pollutant-sources"} className="nav-link">
                Potential Pollutant Sources
              </a></li>
              <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
                Facility Description and Contact Information
              </a></li>
              <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
                Storm Water Control Measures
              </a></li>
              <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
                Schedules and Procedures for Monitoring
              </a></li>
              <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
                Inspections
              </a></li>
              <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
                Sector-Specific Requirements
              </a></li>
              <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
                SWPPP Certification
              </a></li>
              <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
                SWPPP Modifications
              </a></li>
              <li><a href={"/"+siteCode+"/swppp"} className="nav-link disabled">
                Attachments
              </a></li>
            </ul>
          </div>
          <div className="col-md-12 col-lg-9">

            {props.location.pathname === '/'+siteCode+'/swppp' && (
              <>
              <h3>Facility Map</h3>
              <p>A copy of the Drainage Area Facility Map is shown below. The map shows the information required per MSP Part Sector P 3.2.2.1 including, but not limited to Facility activities exposed to stormwater, structures, roads, impervious surfaces, stormwater inlets, outfalls, drainage areas, flow direction, and run-on.</p>
              <div style={{border:'1px solid eee', height: 500}} >
                <MapViewOnly siteId={siteObj.siteId} planObj={planObj} plots={plotsArr} plotTypes={plotTypesArr} mapEl="view" />
              </div>

              <p className="mt-3">As shown, the Facility covers approximately 77 acres, approximately 40% of which is considered impervious surfaces (warehouse, parking). Roof drainage is routed away from the building to outfalls that drain to ponds on the south side of the Facility. Details on the drainage areas associated with industrial activities are detailed below:</p>
              <p>Drainage Area 1 (DA #1) – located on the west side of the Facility, where loading, unloading, fueling, maintenance, and equipment and waste storage activities may occur. Monitoring will be conducted at representative outfalls (M016, M020, and M045).</p>
              <p>Drainage Area 2 (DA #2) – located on the southwest side of the Facility, where loading, unloading, maintenance, and equipment and waste storage activities may occur. Monitoring will be conducted at the representative outfall (M012).</p>
              <p>Drainage Area 3 (DA #3) – located at the southeast side of the Facility, where activities such as loading, unloading, and vehicle washing may occur. Monitoring will be conducted at the representative outfall (M066).</p>
              </>
            )}

            {props.location.pathname === '/'+siteCode+'/swppp/bmps' && (
              <>
              <h3>List of BMPs</h3>
              <table className="table small lh-sm mb-5">
                <thead>
                  <tr>
                    <th scope="col">Plot</th>
                    <th scope="col">Description</th>
                    <th scope="col" width="200">Potential Pollutant Source</th>
                    <th scope="col" width="200">Potential Pollutant(s)</th>
                    <th scope="col" width="200">BMPs</th>
                  </tr>
                </thead>
                <tbody>
                  { plotsArr && plotsArr.filter(obj => !obj.inspectionId).map((obj, index) => {
                    // find the plotType to display
                    const plotType = plotTypesArr.find(type => type.appId === obj.typeId);

                    return (
                      <tr key={'bmp'+index.toString()} >
                        <th scope="row">{ obj.plotName.padStart(3, '0') }</th>
                        <td>{plotType.name}</td>
                        <td><ul>
                          {(typeof obj.arr1 === 'string' || obj.arr1 instanceof String) && (
                            <li>{obj.arr1}</li>
                          )}

                          {(Array.isArray(obj.arr1)) && obj.arr1.map((o,i) => (
                            <li key={'arr1'+i}>{o}</li>
                          ))}
                        </ul></td>
                        <td><ul>
                          {(typeof obj.arr2 === 'string' || obj.arr2 instanceof String) && (
                            <li>{obj.arr2}</li>
                          )}

                          {(Array.isArray(obj.arr2)) && obj.arr2.map((o,i) => (
                            <li key={'arr2'+i}>{o}</li>
                          ))}
                        </ul></td>
                        <td><ul>
                          {(typeof obj.arr3 === 'string' || obj.arr3 instanceof String) && (
                            <li>{obj.arr3}</li>
                          )}

                          {(Array.isArray(obj.arr3)) && obj.arr3.map((o,i) => (
                            <li key={'arr3'+i}>{o}</li>
                          ))}
                        </ul></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              </>
            )}

            {props.location.pathname === '/'+siteCode+'/swppp/potential-pollutant-sources' && (
              <>
              <h3>Potential Pollutant Sources</h3>
              <h4>Industrial Activity and Associated Pollutants</h4>
              <p>Attachment D contains a list of the industrial activities that occur outdoors and their associated pollutants at the Facility. In general, the majority of industrial activities and materials are indoors and are not exposed to stormwater; therefore, they are not included in this SWPPP.</p>
              <h4>Spills and Leaks</h4>
              <p>Potential spills and leaks associated with the industrial activities and materials exposed to stormwater, as listed above, may occur at the Facility that could contribute pollutants to stormwater discharges. Due to the nature of Facility operations, these spills and leaks could occur to outfalls throughout the drainage areas of industrial activity as depicted on the Drainage Area Facility Map (Attachment B).</p>
              <p className="fst-italic">Note: Significant spills and leaks include, but are not limited to, releases of oil or hazardous substances in excess of quantities that are reportable under CWA Section 311 (see 40 CFR 110.6 and 40 CFR 117.21) or Section 102 of the Comprehensive Environmental Response, Compensation and Liability Act (CERCLA), 42 USC §9602.</p>
              {/*<h4>Description of Past Spills/Leaks in the Last Three Years</h4>
              [[ table  ]]*/}
              <h4>Non-Storm Water Discharges Documentation</h4>
              <p>Non-storm Water Discharges (NSWDs) are discharges that do not originate from precipitation. Documentation is required per MSP Part Sector P 3.2.3.7 to evaluate the Facility for the presence of NSWDs and to eliminate unauthorized NSWDs. Unauthorized NSWDs are those not specifically allowed in the TMSP. Typical examples of discharges that are not allowed include:</p>
              <ul>
                <li>Vehicle or equipment rinse or wash water</li>
                <li>Building or pavement rinse or wash water</li>
                <li>Floor (mop) wash water</li>
                <li>Waste container drainage</li>
              </ul>
              <p>Documentation must be maintained that demonstrates that such unauthorized NSWDs have been identified and eliminated by legally disposing in a permitted facility, discharging to a sanitary sewer in accordance with applicable industrial pretreatment requirements, or recycling onsite.</p>
              <p>The following authorized NSWDs may be present at the Facility, including the following:</p>
              <ul>
                <li>Fire hydrant flushings;</li>
                <li>Potable water including water line flushings;</li>
                <li>Uncontaminated air conditioning or compressor condensate;</li>
                <li>Irrigation drainage;</li>
                <li>Landscape watering provided that pesticides, herbicides, and fertilizer have been applied in accordance with manufacturer’s instructions;</li>
                <li>Washing of sidewalks, buildings, etc. to which no detergents have been added; wash water should also be free of other pollutants such as sediment, debris, etc.;</li>
                <li>Uncontaminated groundwater or spring water;</li>
                <li>Foundation or footing drains where flows are not contaminated with process materials such as solvents; and</li>
                <li>Incidental windblown mist from cooling towers that collects on rooftops or adjacent portions of your facility, but NOT intentional discharges from the cooling tower (e.g., “piped” cooling tower blowdown or drains).</li>
              </ul>
              <p>Document the inspection and evaluation of NSWDs at the Facility and retain completed documentation in Attachment J. The Non-Storm Water Certification Form, which can be found in Attachment K, must be completed on a permit-term basis (typically every five years). The Non-Storm Water Certification Form must include a certification signed in accordance with TMSP subpart 7.7 and include the following information for each potential significant source of NSWD identified:</p>
              <ul>
                <li>A description of the results of a test and/or evaluation</li>
                <li>The evaluation criteria or testing method</li>
                <li>The date of testing and/or evaluation</li>
                <li>The onsite drainage points that were observed during the test</li>
              </ul>
              <h4>Salt Storage</h4>
              <p>Storage piles containing salt used for de-icing or other commercial or industrial purposes are required to be enclosed or covered to prevent exposure to precipitation, per TMSP Part 4.5.3, and good housekeeping measures are required. The Facility does not manage bulk storage piles of de-icing materials.</p>
              <p>At the time of the writing of this SWPPP, the Facility manages small quantities (less than 50 pounds) of de-icing materials (including salt and liquid de-icer), which is stored inside the Facility/in a covered location, and used throughout the shipping dock and employee parking areas during icy conditions.</p>
              <h4>Sampling Data Summary</h4>
              <p>A summary of stormwater sampling data collected for the past permit is required to be provided, per MSP Part 3.2.2.4, if applicable. Sampling data collected since implementation of the SWPPP can be found in Attachment J.</p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default withAuthenticationRequired(View, {
  onRedirecting: () => (<Loading />)
});
