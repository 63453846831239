import React from 'react'
import parse from 'html-react-parser'
import ReactPlayer from 'react-player'
// import connectivity  from '../../utils/connectivity.js'

// version 0.12
// added text input
// added reactplayer
// added dynamic text/info (label + html)

// object structure
// type: html, textarea, select, button-group
// column: db column name where this will be saved
// label: parsed html of the label
// description: parsed html above the field but below the label
// options: array of option objects; if option label is null the value = label too
  // this will mostly be used if you want to have the saved db value different from what the user sees
// required: true or omitted
// error: the error message to be displayed

// optional/new
// toggle: for select/checkboxes, if this is true it will show "toggle all" checkbox
// modals: array of objects; if there are additional notes, this draws the modals
  // id: modalId
  // title: text for the title
  // body: parsed html of the modal body

// future:
// think about adding in ways to sort mapped options, e.g. alpha, sortOrder, or by date field?

const Field = (props) => {
  let obj = props.obj;
  let register = props.register;
  let errors = props.errors;
  let setValue = props.setValue;
  let clearErrors = props.clearErrors;

  if(!obj.error) obj.error = 'Required';
  if(obj.label && typeof obj.label === 'string')
    obj.label = parse(obj.label);

  if(obj.description && typeof obj.description === 'string')
    obj.description = parse(obj.description);

  return (
    <>
      { (obj.type === 'html' && typeof obj.value === 'string') && ( parse(obj.value) )}

      { (obj.type === 'dynamic-text' && typeof obj.value === 'string') && (
        <div className="mt-3">
          { obj.label && ( <label className="d-block">{obj.label}</label> )}
          { obj.description && ( obj.description )}

          {parse(obj.value)}
        </div>
      )}

      {/*
        lklklk this doesn't work, you can't send opening tags only...
        will need to figure out if you can nest in the json and loop children that way?
        { obj.type === 'row-start' && ( <div class="row"> )}
        { obj.type === 'row-end'   && ( </div> )}
        { obj.type === 'col-start' && ( <div class="'+obj.value+'"> )}
        { obj.type === 'col-end'   && ( </div> )}
      */}

      { obj.type === 'text' && (
        <div className="mt-3">
          { obj.label && ( <label className="d-block">{obj.label}</label> )}
          { obj.description && ( obj.description )}

          <input type="text" className="form-control shadow-sm" {...register(obj.column, {required: obj.required})} />
          {errors[obj.column] && <div className="error">{obj.error}</div>}
        </div>
      )}

      { obj.type === 'textarea' && (
        <div className="mt-3">
          { obj.label && ( <label className="d-block">{obj.label}</label> )}
          { obj.description && ( obj.description )}

          <textarea className="form-control shadow-sm" {...register(obj.column, {required: obj.required})} rows="3"></textarea>
          {errors[obj.column] && <div className="error">{obj.error}</div>}
        </div>
      )}

      { obj.type === 'dropdown' && (
        <div className="mt-3">
          { obj.label && ( <label className="d-block mb-1">{obj.label}</label> )}
          <select className="form-select shadow-sm"
            {...register(obj.column, {required: obj.required})}
            onChange={e => {
              clearErrors(obj.column)
            }}
          >
            <option></option>

            { obj.options && obj.options.map((option, index) => {
              if(!option.label) option.label = option.value;
              return (
                <option key={ 'option-' + index } value={option.value}>{option.label}</option>
              )
            })}
          </select>
          {errors[obj.column] && <div className="error">{obj.error}</div>}
        </div>
      )}

      { (obj.type === 'checklist' || obj.type === 'dynamic-checklist') && obj.options.length > 0 && (
        <div className="mt-3">
          { obj.label && ( <label className="d-block mb-1">{obj.label}</label> )}
          { obj.toggle && (
            <div className="form-check">
              <label className="form-check-label">
                <input className="form-check-input shadow-sm" type="checkbox"
                  onChange={e => {
                    // check or uncheck all
                    if(e.target.checked) {
                      let values = []
                      let checkboxes = document.getElementsByName(obj.column+'[]');
                      for(var i=0, n = checkboxes.length; i < n; i++) {
                        values.push(checkboxes[i].value);
                      }
                      setValue(obj.column, values)
                    } else {
                      setValue(obj.column,[])
                    }
                  }} />
                Select all
              </label>
            </div>
          )}
          { obj.options && obj.options.map((option, index) => {
            if(!option.label) option.label = option.value;
            return (
              <div key={ 'option-' + index } className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input shadow-sm" type="checkbox" {...register(obj.column+'[]', {required: obj.required})} value={option.value} />
                  {option.label}
                </label>
              </div>
            )
          }
          )}
          {errors[obj.column+'[]'] && <div className="error">{obj.error}</div>}
        </div>
      )}

      { obj.type === 'list-group' && (
        <div className="mt-3">
          { obj.label && ( <label className="d-block mb-1">{obj.label}</label> )}
          { obj.description && ( obj.description )}

          <div className="list-group list-group-checkable">
            { obj.options && obj.options.map((option, index) => {
              if(!option.label) option.label = option.value;
              return (
                <div key={ 'option-' + index }>
                  <input className="list-group-item-check" type="radio" {...register(obj.column, {required: obj.required})} id={obj.column+'-'+index.toString()} value={option.value} />
                  <label className="list-group-item py-3" htmlFor={obj.column+'-'+index.toString()}>
                    {option.label}
                  </label>
                </div>
              )
            })}
          </div>
          {errors[obj.column] && <div className="error">{obj.error}</div>}
        </div>
      )}

      { obj.type === 'button-group' && (
        <div className="mt-3">
          { obj.label && ( <label className="d-block mb-1">{obj.label}</label> )}
          { obj.description && ( obj.description )}
          { obj.options.map((option, index) => {
            // if no label option exists, just use the value
            if(!option.label) option.label = option.value;

            return (
              <div key={"option-"+index} className="d-inline-block">
                <input type="radio" className="btn-check bg-white" autoComplete="off" {...register(obj.column, {required: obj.required})} value={option.value} />
                <label style={{width: 150}} className="btn btn-outline-primary btn-lg shadow-sm me-2"
                  onClick={() => {
                    setValue(obj.column, option.value)
                    clearErrors(obj.column)
                  }}>{option.label}</label>
              </div>
            )
          }
          )}
          {errors[obj.column] && <div className="error">{obj.error}</div>}
        </div>
      )}

      { obj.modals && obj.modals.map((modal, index) => {
        if(modal.body && typeof modal.body === 'string')
          modal.body = parse(modal.body);

        return (
          <div key={"modal-"+index} className="modal fade" id={modal.id} tabIndex="-1" aria-labelledby={modal.id+'Label'} aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id={modal.id+'Label'}>{modal.title}</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  {modal.body}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        )
      })}

{/*
      connectivity().then((res) => {
        if(res) {}
      })
*/}

      { obj.type === 'video' && obj.value && (
        <div className="my-3 player-wrapper">
            <ReactPlayer className="react-player" controls url={obj.value} width="100%" height="100%" />
        </div>
      )}

    </>

  )
};

export default Field;
