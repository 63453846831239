import { fabric } from 'fabric'

// consider what you will need to filter on and only put those in the canvas element
// status
// audit status/color options (vs default maybe?)
// asset type
// variation type
// eventually maybe

export const drawPlot = (plotTypes, inInspection, mapData, plot, childPlot) => {
  var plotName =  plot.plotName.toString().padStart(3, '0');
  var typeShape = 'triangle';
  var typeFill  = '#f00';
  var typeText  = '#fff';
  var strokeWidth = 1;
  var stroke = '#000';

  if(inInspection) {
    strokeWidth = 2;
    stroke = '#ccc';
    // console.log('-------')
    // console.log(' WE ARE IN AN INSEPCTION lock it downnnn')
    // console.log('-------')
    // this means you will need to lock plots that were from setup...
  }

  if(childPlot && childPlot.udf1 && childPlot.udf1 === 'Yes') {
    stroke = '#f00';
  }

  if(childPlot && childPlot.udf1 && childPlot.udf1 === 'No') {
    stroke = '#34985E';
  }

  // console.log(plot)
  // console.log('finding plotType info:')
  // console.log(plot.typeId)


  let found = plotTypes.find(type => type.appId === plot.typeId);

  if(found && found.display) {
    if(found.display.type) typeShape = found.display.type;
    if(found.display.fill)  typeFill  = found.display.fill;
    if(found.display.text)  typeText  = found.display.text;
  }

  let fontFamily = '"Roboto","Segoe UI","Roboto","Helvetica Neue","Arial"';
  let fontSize = 10;
  var c;

  // this works but isn't yet used
  // let labelMultiplier = mapData.labelMultiplier ? mapData.labelMultiplier : 1;
  let locatorMultiplier = mapData.locatorMultiplier ? mapData.locatorMultiplier : 1;

  var plotOptions = {
    appId: plot.appId,
    typeId: plot.typeId,
    status: plot.status,
    left: plot.xCoord,
    top: plot.yCoord
  }

  var text = new fabric.Text(String(plotName), {
    fill: typeText,
    fontSize: fontSize*locatorMultiplier,
    fontFamily: fontFamily
  });

  if(typeShape === 'circle') {
    var circle = new fabric.Circle({
      radius: 13*locatorMultiplier,
      fill: typeFill,
      strokeWidth: strokeWidth,
      stroke: stroke,
    });

    c = new fabric.Group([ circle, text ], plotOptions);

  } else if(typeShape === 'triangle') {
    var triangle = new fabric.Triangle({
      width: 30*locatorMultiplier,
      height: 26*locatorMultiplier,
      fill: typeFill,
      strokeWidth: 1,
      stroke: '#000',
      top: -4
    });

    c = new fabric.Group([ triangle, text ], plotOptions);

  } else {
    // default is rectangle
    var rectangle = new fabric.Rect({
      width: 22*locatorMultiplier,
      height: 22*locatorMultiplier,
      fill: typeFill,
      strokeWidth: strokeWidth,
      stroke: stroke,
    });

    c = new fabric.Group([ rectangle, text ], plotOptions);
  }

  if(inInspection && !plot.inspectionId) {
    c.lockMovementX = true;
    c.lockMovementY = true;
    c.hoverCursor = 'pointer';
  }

  // c.hasBorders = false;
  c.hasControls = false;
  c.hasRotatingPoint = false;
  c.perPixelTargetFind = true;
  c.lockRotation = true;

  return c;

  // var line = makeLine(plot, [ labelX, labelY, locatorX, locatorY ]);
  // canvas.add(line);
  //
  // canvas.add(
  //   makeArchLabel(plot, typeCode, typeFillColor, typeTextColor, labelX, labelY, null, line),
  //   makeArchLocator(plot, typeMarker, locatorX, locatorY, rotation, line, null)
  // );

}
